import {
  ref, watch, onUnmounted, getCurrentInstance,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import moment from 'moment';
import managerStoreModule from '../managerStoreModule';

export default function useManagerList() {
  const STORE_MODULE_NAME = 'manager';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, managerStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();

  const blankItem = {
    name: '',
    phone: '',
    email: '',
    title: '',
    department: '',
    role: null,
    abilities: [],
    password: '',
    passwordConfirm: '',
    active: true,
    apartments: [],
    managerApartments: [],
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  const vm = getCurrentInstance().proxy;
  const isHidden = !vm.$can('update', 'manager') && !vm.$can('delete', 'manager');

  // Table Handlers
  const columns = [
    {
      label: 'Mã',
      field: 'code',
      width: '80px',
    },
    {
      label: 'Thao tác',
      field: 'action',
      tdClass: 'text-center',
      sortable: false,
      width: '100px',
      hidden: isHidden,
    },
    {
      label: 'Họ tên',
      field: 'user.name',
    },
    {
      label: 'Số điện thoại',
      field: 'user.phone',
    },
    {
      label: 'Email',
      field: 'user.email',
    },
    {
      label: 'Loại tài khoản',
      field: 'role.name',
    },
    {
      label: 'Đăng nhập lần cuối',
      field: 'user.lastLogin',
    },
    {
      label: 'Kích hoạt',
      field: 'active',
    },

  ];
  const rows = ref([]);
  const deleteObjects = ref([]);
  // filter
  const apartment = ref(null);
  const room = ref(null);
  const tenant = ref(null);
  const type = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });

  //   API Call
  const fetchManagers = () => {
    isLoading.value = true;
    store
      .dispatch('manager/fetchManagers', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        rows.value = items;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const deleteManagers = managers => {
    store
      .dispatch('manager/deleteManagers', {
        ids: managers.map(_obj => _obj.id),
      })
      .then(() => {
        fetchManagers();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchManagers();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const onEditItem = val => {
    item.value = val;
  };

  const onDelete = val => {
    deleteObjects.value = val && val.id > 0 ? [val] : selectedRows.value;
  };

  //   Watch

  watch(apartment, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.apartmentId = val.id;
    } else {
      delete filter.apartmentId;
    }
    updateParams({ filter });
  });
  watch(room, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.roomId = val.id;
    } else {
      delete filter.roomId;
    }
    updateParams({ filter });
  });
  watch(tenant, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.tenantId = val.id;
    } else {
      delete filter.tenantId;
    }
    updateParams({ filter });
  });
  watch(type, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.typeId = val.id;
    } else {
      delete filter.typeId;
    }
    updateParams({ filter });
  });

  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });

  const resolveLastLoginTime = val => {
    if (moment(val).isValid()) {
      return moment(val).format("DD-MM-YYYY HH:mm");
    }
    return "Chưa đăng nhập";
  };

  return {
    item,
    columns,
    rows,
    apartment,
    room,
    tenant,
    type,
    selectedRows,
    deleteObjects,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,

    fetchManagers,
    deleteManagers,
    onEditItem,
    resetItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onDelete,
    onColumnFilter,
    selectionChanged,
    resolveLastLoginTime,
  };
}
