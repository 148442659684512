import {
  ref, computed,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import managerStoreModule from '../managerStoreModule';

export default function useDeleteManagerModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'manager';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, managerStoreModule);
  }

  const toastification = toast();
  const isSubmitting = ref(false);
  const refModalLiquidation = ref(null);
  const refForm = ref(refFormObserver);
  const blankItem = {
    items: JSON.parse(JSON.stringify(props.items)),
    user: null,
  };
  const itemLocal = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(blankItem));
    isSubmitting.value = false;
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const managers = computed(() => JSON.parse(JSON.stringify(props.items)));

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const data = {
      ids: managers.value.map(obj => obj.id),
      new_manager_id: itemLocal.value.user.id,
    };

    isSubmitting.value = true;
    store
      .dispatch('manager/deleteManagers', data)
      .then(response => { emit('on-item-updated', response.data); })
      .then(() => {
        refModalLiquidation.value.toggle('#toggle-btn');
      })
      .then(() => {
        emit('refetch-data');
      })
      .then(() => {
        toastification.showToastDeleteSuccess();
        isSubmitting.value = false;
      })
      .catch(error => {
        toastification.showToastError(error, refForm.value);
        isSubmitting.value = false;
      });
  };

  return {
    refModalLiquidation,
    refForm,
    itemLocal,
    managers,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
  };
}
