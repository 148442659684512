<template>
  <b-modal
    id="modal-delete-manager"
    ref="refModalLiquidation"
    title="Xóa tài khoản nhân viên"
    size="lg"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Xác nhận xóa'"
    :ok-disabled="isSubmitting"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >

      <b-alert
        show
        variant="danger"
      >
        <div class="alert-body">
          <feather-icon
            class="mr-25"
            icon="AlertTriangleIcon"
          />
          <span class="ml-25">Hãy cẩn thận khi xóa tài khoản người dùng. Nếu bạn xóa nhầm, Resident sẽ không thể khôi phục dữ liệu như ban đầu. Việc bạn thao tác xóa đồng nghĩa với việc bạn sẽ chịu mọi trách nhiệm về mặt dữ liệu sau khi xóa.</span>
        </div>
      </b-alert>
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                1. Danh sách tài khoản bị xóa
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <manager-table :data="managers" />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                2. Tài khoản tiếp nhận dữ liệu
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="liquidDate"
              rules="required"
            >
              <select-manager
                v-model="itemLocal.user"
                required
                :ignore-ids="managers.map(obj => obj.id)"
                label="Chọn tài khoản"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BRow,
  BCol,
  BAlert,
} from 'bootstrap-vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import useDeleteManagerModal from './useDeleteManagerModal';
import ManagerTable from './ManagerTable.vue';

export default {
  components: {
    BModal,
    BAlert,
    BRow,
    BCol,

    BForm,
    ValidationObserver,
    ValidationProvider,

    ManagerTable,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalLiquidation,
      itemLocal,
      managers,
      isSubmitting,
      resetItemLocal,
      resetModal,

      onSubmit,
    } = useDeleteManagerModal(props, emit, refFormObserver);

    return {
      refModalLiquidation,
      itemLocal,
      managers,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
